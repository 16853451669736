import styled from 'styled-components';

export const Container = styled.div`
  label {
    font-family: P22 Underground;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #213f4e;
    width: 100%;
  }
  input {
    background: #ffffff;
    border: 1px solid rgba(33, 63, 78, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 50px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
    font-family: P22 Underground;
    font-size: 16px;
    color: #213f4e;
    padding: 0px 10px;
  }
  button {
    margin-top: 10px;
    background: #e26447;
    border-radius: 4px;
    width: 100%;
    border: none;
    min-height: 54px;
    font-family: P22 Underground;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
  }

  button:hover {
    background: #b45038;
  }

  button:active {
    background: #b45038;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  @media (max-width: 800px) {
    label {
      font-size: 16px;
    }
    input {
      font-size: 16px;
    }
    button {
      font-size: 18px;
    }
  }
`;
