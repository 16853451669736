import React, { useState } from 'react';
import * as S from './FormInput.styles';
import axios from 'axios';

const FormInput = props => {
  const [email, setEmail] = useState(null);
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const { email_list, redirect_url } = props;

  let payload = {
    email,
    first_name,
    last_name,
    email_list
  };
  const handleSubmit = async e => {
    e.preventDefault();
    const response = await axios.post('/.netlify/functions/klaviyo', payload);

    if (response.status === 200) {
      window.location = redirect_url;
    }
  };

  return (
    <S.Container>
      <form id="email_form" onSubmit={handleSubmit}>
        <label htmlFor="first_name">First Name</label>
        <input
          type="text"
          name="first_name"
          id="first_name"
          onChange={e => setFirstName(e.target.value)}
          required
          value={first_name || ''}
        />
        <label htmlFor="last_name">Last Name</label>
        <input
          type="text"
          name="last_name"
          id="last_name"
          onChange={e => setLastName(e.target.value)}
          required
          value={last_name || ''}
        />
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          name="email"
          id="email"
          onChange={e => setEmail(e.target.value)}
          required
          value={email || ''}
        />
        <button type="submit">SEE PRODUCT</button>
      </form>
    </S.Container>
  );
};

export default FormInput;
